<template>
  <div class="ma-auto mb-4">
    <v-row
      class="px-4 pt-4"
      align="center"
    >
      <div class="page-header-left">
        <h3 class="pr-3">
          Personal Billing
        </h3>
        <v-breadcrumbs
          :items="breadcrumbsItems"
          exact
          class="pa-0"
          divider="/"
        />
      </div>
      <v-spacer />
      <div class="page-header-right">
        <!-- <v-btn icon><v-icon>sentiment_satisfied_alt</v-icon></v-btn> -->
      </div>
    </v-row>
    <v-container fluid>
      <v-row>
        <router-view
          v-if="route"
          :create-alert="createAlert"
          :route="route"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Billing',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {
      }
    }
  },
  data () {
    return {
      breadcrumbsItems: [{
        text: 'Billing',
        to: { name: 'user-billing' },
        exact: true
      }],
      route: null
    }
  },
  computed: {
    ...mapState([
      'brandName'
    ])
  },
  created () {
    this.route = 'verifications'
  },
  mounted () {
    this.$root.$on('selectBill', bill => {
      this.viewBill(bill)
    })
    this.$root.$on('viewBilling', () => {
      this.viewBilling()
    })
  },
  beforeDestroy () {
    this.$root.$off('selectBill')
    this.$root.$off('viewBilling')
  },
  methods: {
    viewBill (bill) {
      this.breadcrumbsItems.push({
        text: bill.transactionId,
        to: { name: 'user-bill', query: { id: bill.id } },
        exact: true,
        disabled: true
      })
    },
    viewBilling () {
      this.breadcrumbsItems.splice(1)
    }
  }
}
</script>
